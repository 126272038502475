<template>
  <div>
    <component :is="layout">
      <router-view />
    </component>
    <notifications position="bottom right" />
  </div>
</template>

<script>
const defaultLayout = "default";
// import VueMeta from "vue-meta";

export default {
  computed: {
    layout: function() {
      return `${this.$route.meta.layout || defaultLayout}-layout`;
    },
  },
  // metaInfo: {
  //   meta: [
  //     {
  //       "http-equiv": "Content-Security-Policy",
  //       content: "upgrade-insecure-requests",
  //     },
  //   ],
  // },
};
</script>
<style>
body {
  overflow-y: scroll; /* Show vertical scrollbar */
  overflow-x: scroll; /* Show horizontal scrollbar */
}
</style>
