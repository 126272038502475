import {
    get,
    post
} from "@/network/ajax";


export default {

    login(params) {
        return post("/users/login", params);
    },
    users(params) {
        return get("/users", params);
    }
}