<template>
  <header
    class="flex justify-between items-center py-4 px-6 bg-white border-b-4 border-indigo-600"
  >
    <div class="flex items-center ">
      <button
        @click="clickMenu"
        class="text-gray-500 focus:outline-none lg:hidden"
      >
        <svg
          class="h-6 w-6"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 6H20M4 12H20M4 18H11"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </div>
    <div class="flex items-center">
      <p class="mr-3">Hi~ {{ username }}</p>
      <button
        class=" rounded-md bg-indigo-400 px-3 py-1 text-white"
        v-on:click="logout"
      >
        登出
      </button>
    </div>
  </header>
</template>
<script>
import { SAVE_SIDE_OPEN_STATUS, CLEAR_USER_INFO } from "@/store/mutations-type";
export default {
  computed: {
    username: function() {
      return this.$store.state.user.appUserName;
    },
  },
  methods: {
    clickMenu: function() {
      this.$store.commit("appInfo/" + SAVE_SIDE_OPEN_STATUS, true);
    },
    logout: function() {
      this.$store.commit("user/" + CLEAR_USER_INFO);
      this.$router.push({ name: "login" });
    },
  },
};
</script>
