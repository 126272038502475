import user from '@/network/user'
import {
    SAVE_USER_INFO,
    SAVE_USER_NAME,
    REMEMBER_ACCOUNT,
    CLEAR_USER_INFO
} from '../mutations-type'



const state = {
    token: localStorage.token,
    userId: localStorage.userId,
    rememberAccount: localStorage.rememberAccount,
    username: localStorage.username,
    appUserName: localStorage.appUserName
}

const actions = {
    login({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            user.login(data).then(res => {
                resolve();
                commit(SAVE_USER_INFO, res);
            }).catch(err => {
                reject(err);

            })
        })
    }
}

const mutations = {
    [SAVE_USER_INFO](
        state,
        data
    ) {
        state.token = data.id;
        state.userId = data.userId;
        state.appUserName = data.userName;
        localStorage.token = data.id;
        localStorage.userId = data.userId;
        localStorage.appUserName = data.userName;
    },
    [SAVE_USER_NAME](state, data) {
        state.username = data;
        localStorage.username = data;
    },
    [REMEMBER_ACCOUNT](state, data) {
        state.rememberAccount = data;
        localStorage.rememberAccount = data;
    },
    [CLEAR_USER_INFO](state) {
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        localStorage.removeItem('appUserName');
        state.token = null;
        state.userId = null;
        state.appUserName = null;
    }

}


export default {
    namespaced: true,
    state,
    actions,
    mutations
}