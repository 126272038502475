import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueMeta from 'vue-meta'
import "@/assets/css/tailwind.css";
import dayjs from "dayjs";
import Notifications from "vue-notification";

import AppModal from "@/components/AppModal";

import DashboardLayout from "@/components/DashboardLayout.vue";
import EmptyLayout from "@/components/EmptyLayout.vue";
import Select2 from 'v-select2-component';


Vue.config.productionTip = false;

Vue.component('Select2', Select2);
Vue.component("default-layout", DashboardLayout);
Vue.component("empty-layout", EmptyLayout);
Vue.component("app-modal", AppModal);

Vue.use(Notifications);
Vue.use(VueMeta)

Vue.prototype.$dayjs = dayjs; //赋值使用

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");