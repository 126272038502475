import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import appInfo from './modules/appInfo'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

let store = new Vuex.Store({
    modules: {
        user,
        appInfo
    },
    strict: debug
})

export default store