import {
    SAVE_SIDE_OPEN_STATUS
} from '../mutations-type'

const state = {
    sideBarOpen: false
}

const mutations = {
    [SAVE_SIDE_OPEN_STATUS](
        state,
        data
    ) {
        state.sideBarOpen = data;
    }
}

export default {
    namespaced: true,
    state,
    mutations
}